<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    ref="multipleTable"
    tooltip-effect="dark"
    style="width: 100%"
  >
    <el-table-column
      prop="supplier.business.name"
      align="center"
      label="集配中心"
    >
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.business?.name) }}
      </template>
    </el-table-column>
    <el-table-column prop="supplier.title" align="center" label="供应商">
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.title) }}
      </template>
    </el-table-column>
    <el-table-column prop="supplier.id" align="center" label="供应商ID">
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.id) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="supplier.supplier_bank.username"
      align="center"
      label="持卡人"
    >
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.supplier_bank?.username) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="supplier.idcard_name"
      align="center"
      label="身份证姓名"
    >
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.idcard_name) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="supplier.idcard_code"
      align="center"
      label="身份证号码"
    >
      <template slot-scope="scope">
        {{ $empty.empty(scope.row?.supplier?.idcard_code) }}
      </template>
    </el-table-column>
    <el-table-column prop="revenue_expend_type" align="center" label="收支">
      <template slot-scope="scope">
        {{
          incomeType.find(
            (item) => item.value == scope.row?.revenue_expend_type
          ).label || "-"
        }}
      </template>
    </el-table-column>
    <el-table-column prop="amount" align="center" label="金额">
      <template slot-scope="scope">
        {{ tool.format_money(scope.row?.amount) }}
      </template>
    </el-table-column>
    <el-table-column prop="create_time" align="center" label="时间">
      <template slot-scope="scope">
        {{ scope.row?.create_time || "-" }}
      </template>
    </el-table-column>
    <el-table-column prop="type" align="center" label="类型">
      <template slot-scope="scope">
        <span>{{ getTypeName(scope.row?.type) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="desc" align="center" label="描述">
      <template slot-scope="scope">
        {{ scope.row?.desc || "-" }}
      </template>
    </el-table-column>
    <el-table-column prop="wallet_amount" align="center" label="账户余额">
      <template slot-scope="scope">
        {{ tool.format_money(scope.row?.wallet_amount) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    typeList: {
      type: Array,
      default: () => [],
    },
    incomeType: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    /**
     * 获得类型名称
     */
    getTypeName() {
      return (data) => {
        const res = this.typeList.find((item) => item.val == data)?.name;
        return res || "-";
      };
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
