<template>
  <div class="orderlist bj freight-container">
    <div class="title">
      <div class="d-flex a-center">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="formData.business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="开始时间起"
            end-placeholder="开始时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs multiple-select">
          <el-select
            @change="supplierChange"
            filterable
            v-model="selectSupplierId"
            clearable
            placeholder="选择供应商"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.supplier_id"
            placeholder="供应商id"
            clearable
            @input="handleInput"
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.idcard_name"
            placeholder="身份证姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.bank_username"
            placeholder="持卡人"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.idcard_code"
            placeholder="身份证号"
            clearable
          ></el-input>
        </div>
        <div class="inputs d-flex a-center">
          <el-input
            v-model="formData.min_wallet_amount"
            placeholder="余额起"
            clearable
            @input="
              handleInputMoney(formData.min_wallet_amount, 'min_wallet_amount')
            "
          ></el-input>
          <div class="">-</div>
          <el-input
            v-model="formData.max_wallet_amount"
            placeholder="余额止"
            clearable
            @input="
              handleInputMoney(formData.max_wallet_amount, 'max_wallet_amount')
            "
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.type" clearable placeholder="类型">
            <el-option
              v-for="item of typeList"
              :key="item.val"
              :label="item.name"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.revenue_expend_type"
            clearable
            placeholder="收支"
          >
            <el-option
              v-for="item of incomeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <table-list
        :tableData="tableData"
        v-loading="loading"
        :typeList="typeList"
        :incomeType="incomeType"
      ></table-list>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { DYNAMIC_INCOME_TYPE_ENUM } from "../utils/enum/index.js";
import TableList from "./modules/table-list";
import {
  postSupplierWalletFlow,
  postSupplierWalletFlowType,
} from "@/api/generalControl/deduction-manage/index.js";
import { getSupplierSelectList } from "@/api/general/operation-center/index.js";
import { postSupplierWalletFlowExport } from "@/api/export/center.js";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "supplier-dynamic-data",
  components: { TableList },
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pay_at: "",
      typeList: [], // 类型列表
      incomeType: Object.values(DYNAMIC_INCOME_TYPE_ENUM), // 收支类型
      formData: {
        business_id: "",
        start_time: "",
        end_time: "",
        supplier_id: "",
        idcard_name: "",
        idcard_code: "",
        bank_username: "",
        type: "",
        revenue_expend_type: "",
        min_wallet_amount: "",
        max_wallet_amount: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [],
      selectSupplierId: "",
      total: 0,
      supplierList: [],
      logisticsList: [], // 集配中心列表
      disabledExport: false, // 导出禁用（导出列表）
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.hqlist();
    this.getAjaxLogisticsList();
    this.postAjaxSupplierWalletFlowType();
    this.getAjaxSupplierSelectList();
  },
  methods: {
    /**
     * 供应商id 文本框只能输入数字
     */
    handleInput(value) {
      this.formData.supplier_id = value.replace(/[^\d]/g, "");
    },
    /**
     * 余额 文本框 正则表达式来限制输入，只允许负数、正数、0以及小数
     */
    handleInputMoney(value, name) {
      this.formData[name] = value.replace(/[^\-?\d.]/g, "");
    },
    /**
     * 获取类型列表
     */
    async postAjaxSupplierWalletFlowType() {
      try {
        const { data } = await postSupplierWalletFlowType();
        this.typeList = data;
      } catch (err) {
        console.log("ajax postSupplierWalletFlowType err", err);
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获得供应商下拉列表
     */
    async getAjaxSupplierSelectList() {
      try {
        const { data } = await getSupplierSelectList({
          is_all: 0, //  0或不传为权限下数据
          is_state: 2, // 2 启用禁用都返回
        });
        this.supplierList = data;
      } catch (err) {
        console.log("ajax getSupplierSelectList err", err);
      }
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postSupplierWalletFlowExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postPurchaseSettleExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 下拉供应商列表
     */
    supplierChange(val) {
      console.log(this.val, "===");
      this.formData.supplier_id = val;
    },
    /**
     * 查询
     */
    search() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 刷新重置
     */
    Refresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.selectSupplierId = "";
      this.formData.page = 1;
      this.pay_at = "";
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    handleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    /**
     * 时间发生改变
     */
    payChange(val) {
      console.log(val);
      if (val) {
        this.formData.start_time = val[0];
        this.formData.end_time = val[1];
      } else {
        this.formData.start_time = "";
        this.formData.end_time = "";
      }
    },
    /**
     * 列表数据
     */
    async hqlist() {
      this.loading = true;
      try {
        const res = await postSupplierWalletFlow(this.formData);
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postSupplierWalletFlow");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.freight-container.orderlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .bjsearch {
    background: #333333;
    border-color: #333333;
    margin-right: 5px;
  }

  .bjsearch:active {
    background: #333333;
    border-color: #333333;
  }

  .checked-box {
    padding: 13px 15px;
  }

  .title {
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .title-style {
      color: #999999;
      span {
        color: #13ae67;
        font-size: 20px;
      }
    }
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        margin-right: 8px;
        margin-bottom: 5px;
      }

      .multiple-select {
        width: 250px;
      }
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
